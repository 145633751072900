import * as React from "react";
import styled from "styled-components";
import PageHeader from "../../components/page-header";

const Service = () => {
  return (
    <>
      <PageHeader title="SERVICE" imgSrc="/service-header.jpeg" />
    </>
  );
};

export default Service;

const About = () => (
  <Caption
    title="About Antyba"
    description={[
      "Antyba.incは、デジタルテクノロジーを生かしたコンテンツ開発を中心に",
      "あらゆるお客様のご要望を叶えさせていただきます。",
    ]}
  />
);

const Caption = (props: { title: string; description: string[] }) => (
  <div style={{ width: "100%", marginTop: "100px" }}>
    <CaptionHeader>{props.title}</CaptionHeader>
    <div>
      {props.description.map((x, index) => (
        <CaptionDescription key={`${props.title}-${index}`}>
          {x}
        </CaptionDescription>
      ))}
    </div>
  </div>
);

const CaptionHeader = styled.div`
  font-size: 32px;
  font-weight: 500;
  letter-spacing: 0.15em;
  line-height: 2.5;
  text-align: center;
`;

const CaptionDescription = styled.div`
  font-size: 14px;
  font-weight: 500;
  letter-spacing: 0.15em;
  line-height: 2.5;
  text-align: center;
`;
