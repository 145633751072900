import * as React from "react";
import Layout from "../presentation/components/layout";
import Top from "../presentation/containers/top";
import Works from "../presentation/containers/works";
import Head from "../presentation/components/head";
import Service from "../presentation/containers/service";

// markup
const ServicePage = () => {
  return (
    <>
      <Head
        subtitle="Service"
        description="Antyba合同会社のサービスを掲載しています。"
      />
      <Layout>
        <Service />
      </Layout>
    </>
  );
};

export default ServicePage;
